
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

























































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.voo-for-employee,
[class*='voo-for-employee--'] {
  position: relative;

  ::v-deep {
    .hero__slides__item__title {
      margin-bottom: $spacing * 1.5;
    }

    .hero__slides__item__highlight {
      margin-top: $spacing * 3;
      text-transform: none;
    }
  }
  ::v-deep .advantage {
    display: none;
  }
}
