
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






















































































































/* stylelint-disable declaration-no-important */

.content-switcher,
[class*='content-switcher--'] {
  @include bg-color;

  padding-bottom: 0 !important;
}

.content-switcher__title,
.content-switcher__excerpt {
  padding: 0 $spacing;
  font-family: $ff-alt;
  text-align: center;
}

.content-switcher__title {
  margin-top: 0;
  margin-bottom: $spacing;
}

.content-switcher__switcher {
  margin: $spacing * 2 0 $spacing * 2.5;
  padding: 0 $spacing;
  text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
