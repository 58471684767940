
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        































































































































.compare-table {
  @extend %table-legacy;
  // @include bg-color;

  .message,
  .table-content__header__sale {
    display: none;
  }

  ::v-deep .selection {
    margin-bottom: $spacing * 1.5;
  }

  ::v-deep .price {
    margin-bottom: $spacing * 0.5;
  }
}

.compare-table__title {
  max-width: 65rem;
  margin-inline: auto;
  font-family: $ff-alt;
  text-align: center;
}

.compare-table-table {
  position: relative;

  .table__layout {
    grid-template-rows: 28rem 1fr 0;
  }

  .table-content__header__block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: auto;
    padding-top: $spacing;
    padding-bottom: $spacing;

    h4 {
      margin-bottom: $spacing * 0.5;
    }
  }

  .table-content__header {
    @include mq($until: xl) {
      background-color: transparent;
    }
  }

  .table-content__data__spec-outer {
    &:nth-child(odd) {
      background-color: rgba($white, 0.1);
    }

    &:nth-child(even) {
      @include mq($until: xl) {
        .table-content__data__value {
          color: $white;
          background-color: rgba(#5fbec8, 0.8);
        }
      }

      @include mq(xl) {
        color: $white;
        background-color: rgba(#5fbec8, 0.8);
        border-bottom-left-radius: 1rem;
      }
    }
  }

  .table-content__order__button-outer {
    &:first-child {
      display: flex;
    }
  }
}
